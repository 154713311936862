import './HeaderThanksYou.css'
import React from 'react'

function TYHeader() {
    return (
      <div className='TYheader'>
        <div className='TYheader-area'>
          <img src='./logo.png' alt='Logo da LetterConsulting'/>
            <div className='TYheader-contato'>
              <a href='https://api.whatsapp.com/send?phone=5577999049195'><img src='./contato.png' alt='icone de contato'/>Contato</a>
            </div>
        </div>
      </div>
    )
}

export default TYHeader





