import FaleConosco from '../FaleConosco/FaleConosco'
import './MainSection.css'

function MainSection() {


            setInterval(() => {
                const section1 = document.querySelector('.mainSection-area')
                if(section1) {
                    section1.classList.add('morph')
                    section1.classList.remove('opacity')
                }
            }, 0);
            setInterval(() => {
                const section1 = document.querySelector('.mainSection-area2')
                if(section1){
                    section1.classList.add('morph')
                    section1.classList.remove('opacity')
                }

            }, 9200);
            setInterval(() => {
                const section1 = document.querySelector('.mainSection-area3')
                if(section1){
                    section1.classList.add('morph')
                    section1.classList.remove('opacity')
                }
            }, 18400);
            clearInterval(setInterval)

    

    return (
                <div className='mainSection'>
    
                <div className='mainSection-area3 opacity'>
    
                        <div className='mainSection-text'>
                            <h1>Aumente seus lucros com a recuperação de crédito</h1>
                            <p>A <strong>Letter Consulting</strong> atua como <strong>referência</strong> na <strong>recuperação</strong> de impostos, como <strong>ICMS, ISS, PIS e COFINS</strong> pagos <strong>indevidamente</strong> por empresas de diversos segmentos.</p>
                            <div className='botao-verde'>
                                <a href='https://api.whatsapp.com/send?phone=5577999049195'>Começar agora mesmo!</a>
                            </div>
                        </div>
    
    
                </div>
                
                <div className='mainSection-area2 opacity'>
    
                        <div className='mainSection-text'>
                            <h1>Descubra se sua empresa pode estar perdendo dinheiro!</h1>
                            <p>Com <strong>mais de 15 anos de atuação</strong>, já recuperamos <strong>mais de R$ 9.4 bilhões</strong> para empresas em <strong>todo o Brasil</strong>. Tenha uma <strong>análise detalhada</strong> e descubra como <strong>reaver valores devidos</strong>.</p>
                            <div className='botao-verde'>
                                <a href='https://api.whatsapp.com/send?phone=5577999049195'>falar com um especialista</a>
                            </div>
                        </div>
    
                </div>
    
                <div className='mainSection-area opacity'>
    
                        <div className='mainSection-text'>
                            <h1>Amplie suas oportunidades de crédito e faça sua empresa crescer!</h1>
                            <p>Combinando <strong>tecnologia avançada</strong> e <strong>expertise</strong> de um <strong>time especializado</strong>, estamos <strong>comprometidos em ajudar</strong> a <strong>sua empresa</strong> a alcançar <strong>seus objetivos financeiros</strong>.</p>
                            <div className='botao-verde'>
                                <a href='https://api.whatsapp.com/send?phone=5577999049195'>Como funciona?</a>
                            </div>
                        </div>
    
    
                </div>
                    <div className='mainSection-form'>
                        <FaleConosco />
                    </div>
            </div>
    )
}

export default MainSection