import './Footer.css'

function Footer() {

        const ano = new Date().getFullYear();


    

    return (
        <div className='footer'>
            <div className='footer-menu'>
                <a href='/' onClick={window.scrollTo(0,0)}><img id='home' src='./logo-amarelo.png' alt='Logo da LetterConsulting'/></a>
                <div className='footer-menu-menu'>
                    <ul>
                        <li><a href='#diferenciais'>Diferenciais</a></li>
                        <li><a href='#sobre'>Sobre a Letter</a></li>
                        <li><a href='#servicos'>Serviços</a></li>
                        <li><a href='https://letterconsulting.com.br/politica-de-privacidade' target='_blank' rel='noreferrer'>Política de Privacidade</a></li>
                    </ul>
                </div>
                {/* <div className="footer-icons">
                    <a href="https://www.linkedin.com/company/rocha-letter-consulting/" target="blank" alt="Linkedin"><i className="fa-brands fa-linkedin"></i></a>
                    <a href="https://www.instagram.com/rocha.letterconsulting/" target="blank" alt="Instagram"><i className="fa-brands fa-instagram"></i></a>
                    <a href="https://www.facebook.com/people/Rocha-Letter-Consulting/100084822468759/" target="blank" alt="Facebook"><i className="fa-brands fa-square-facebook"></i></a>
                </div> */}
            </div>
            <div className='footer-menu2'>
                <div className='footer-dados-contatos'>
                        <a href="https://api.whatsapp.com/send?phone=5577999049195" alt="Whatsapp" target='_blank' rel='noreferrer' ><i className="fa-solid fa-phone"></i>+55 (77) 99904.9195</a>
                        <p><i className="fa-solid fa-envelope"></i>leonardo.fernandes@letterconsulting.com.br</p>
                    </div>
                    <img src='./alloy.png' alt="Letter Consulting" title="Letter Consulting"/>
            </div>
            <div className='copyright'>
                <p>© copyright {ano}  - Todos os direitos reservados.</p>
            </div>       
        </div>
    )
}

export default Footer