import { useState } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'

function SaibaMaisFooter() {

    const [anoAtual] = useState(getYear())

    function getYear() {
        const ano = new Date().getFullYear();
        return ano
    }

    function goToHome() {
        setTimeout(() => {
            const goToView = document.getElementById('')
            goToView.scrollIntoView()
        }, 1000);
    }

    function goToDiferenciais() {
        setTimeout(() => {
            const goToView = document.getElementById('diferenciais')
            goToView.scrollIntoView()
        }, 1000);
    }

    function goToSobre() {
        setTimeout(() => {
            const goToView = document.getElementById('sobre')
            goToView.scrollIntoView()
        }, 1000);
    }

    return (
        <div className='saibaMaisfooter'>
            <div className='footer-area'>
                <div className='footer-menu'>
                    <Link to='/' onClick={goToHome}><img src='./logo-amarelo.png' alt=''/></Link>
                    <div className='footer-menu-menu'>
                        <ul>
                            <li><Link to='/' onClick={goToDiferenciais}>Diferenciais</Link></li>
                            <li><Link to='/' onClick={goToSobre}>Sobre a Letter</Link></li>
                            <li><a href='/' className='active'>Serviços</a></li>
                            <li><a href='https://letterconsulting.com.br/politica-de-privacidade' target='_blank' rel='noreferrer'>Política de Privacidade</a></li>
                        </ul>
                    </div>
                    <div className="footer-icons">
                        <a href="https://www.linkedin.com/company/rocha-letter-consulting/" target="blank" alt="Linkedin"><i className="fa-brands fa-linkedin"></i></a>
                        <a href="https://www.instagram.com/rocha.letterconsulting/" target="blank" alt="Instagram"><i className="fa-brands fa-instagram"></i></a>
                        <a href="https://www.facebook.com/people/Rocha-Letter-Consulting/100084822468759/" target="blank" alt="Facebook"><i className="fa-brands fa-square-facebook"></i></a>
                    </div>
                </div>
                <div className='footer-menu2'>
                    <div className='footer-dados-contatos'>
                        <a href="https://api.whatsapp.com/send?phone=5577999049195" alt="Whatsapp"><i className="fa-solid fa-phone"></i>+55 (77) 99904.9195</a>
                        <p><i className="fa-solid fa-envelope"></i>leonardo.fernandes@letterconsulting.com.br</p>
                    </div>
                    <img src='./alloy.png' alt="Letter Consulting" title="Letter Consulting" />
                </div>
                <div className='copyright'>
                    <p>© copyright {anoAtual}  - Todos os direitos reservados.</p>
                </div>
            </div>
        </div>
    )
}

export default SaibaMaisFooter